@tailwind base;
@tailwind components;
@tailwind utilities;

@import './animations.css';

body {
  font-family: 'Noto Sans', sans-serif;
}

mono {
  font-family: 'Noto Sans Mono', monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
